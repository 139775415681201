const relax = [
  {
    title: 'Ben and Hollys Little Kingdom',
    description:
      'Welcome to Ben and Hollys Little Kingdom, a tiny land where flowers and grass...',
    image: 'https://yt3.ggpht.com/a/AGF-l7-miQ9SeAYEGCy4IZbrEDpmeQHXAUjb2tzuYA=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UC2UhuvjTIrR0Ck2KrkvRcuA',
  },
  {
    title: 'Peppa Pig',
    description:
      'Peppa lives with her mummy and daddy and her little brother, George.',
    image: 'https://yt3.ggpht.com/a/AGF-l7-UmtqMbAqf7gkjyV7tTuM1Iafj-GB-b4Qmuw=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCAOtE1V7Ots4DjM8JLlrYgg',
  },
  {
    title: 'Bananas in Pyjamas',
    description:
      'The best of the adventures of B1, B2, the Teddies and all of the gang.',
    image: 'https://yt3.ggpht.com/a/AGF-l7-lq7uxENSzi4sz106YLh71iYAwnn6ljO7jPg=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCug61OHMkz5GgJkeyhxdepQ',
  },
  {
    title: 'Bob the Builder',
    description:
      'Join Bob and team as they work on building projects in Fixham and Spring City.',
    image: 'https://yt3.ggpht.com/a/AGF-l7-9bLVLr5WU2WcieRYUmwzADn5M95rsuX9aQA=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCXv3404_UQsWG46lTgx2MRQ',
  },
   {
    title: 'Peter Rabbit',
    description:
      'Full of adventure and excitement, tapping into children’s innate desire for exploration',
    image: 'https://yt3.ggpht.com/a/AGF-l7_Vqv1aXFNnB3-h92yJ38oJ0-pqdIxnsl6F6Q=s48-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCngkVoq8aayyK6qX9JJitRg',
  },
  {
    title: 'Just for Laughs: Kid Pranks',
    description:
      'The best pranks on kids, with kids, and FOR kids here on Just For Laughs Gags!',
    image: 'https://yt3.ggpht.com/a/AGF-l7_ejM3RI7IehZZ-cQwSgMRKrPblw-1uq4EQ_A=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/watch?v=b1Uk_HjKGk8&list=PLCL1mm9mxsAJj7e0T1K3Zpb8LwUPwbknq',
  },
  {
    title: 'He-Man Official',
    description:
      'Episodes of the classic 80s show He-man.',
    image: 'https://yt3.ggpht.com/a/AGF-l7-q_CKVKRHjuiHECFdAuLiwpZQQNQpFKUZNlg=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/user/HeMan/videos',
  },
  {
    title: 'Maisy Mouse Official',
    description:
      'Maisy is both a series of books and an animated series by Lucy Cousins.',
    image: 'https://yt3.ggpht.com/a/AGF-l7_zYfFelIlqpgPhwhZ7Q0GhvDC3WG04in7s6w=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UC6PkaI25MZai1547O9bTeqQ/videos',
  },
  {
    title: 'Postman Pat Official',
    description:
      'Each story features Postman Pat and his black and white cat Jess and their adventures in the village of Greendale. ',
    image: 'https://yt3.ggpht.com/a/AGF-l7--DeywGlIAHltp_yzryYuKnpsJvWa4O2IH7Q=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/user/PostmanPatSDS/videos',
  },
  {
    title: 'The Land Before Time',
    description:
      'The Land Before Time, introduces us to Littlefoot, Cera, Spike, Ducky and Petrie, and follows them on their first journey together.',
    image: 'https://yt3.ggpht.com/a/AGF-l78zgaC7hdX3H2owYpjt8FWMuagz6DRDysVqhA=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCGTPGtMd8ydRNsTcKaDwF1w/videos',
  },
  {
    title: 'Curious George Official',
    description:
      'Curious George is a sweet African monkey who can not help but run into trouble.',
    image: 'https://yt3.ggpht.com/a/AGF-l79NVDokXpCeY9UvAle6_B7z37wrh9kNQjZAHA=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCu7IDy0y-ZA0qaG51wrQY6w/videos',
  },
 {
    title: 'Bravestarr Official',
    description:
      'Episodes of the classic 80s show Bravestarr.',
    image: 'https://yt3.ggpht.com/a/AGF-l7_ccW9JAkLRfL15SRS20FFe2pJfLaI91Y6-MA=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/user/BravestarrOfficial/videos',
  },
  {
    title: 'She Re: Princess of Power',
    description:
      'Episodes of the classic 80s cartoon She-Ra',
    image: 'https://yt3.ggpht.com/a/AGF-l78nHq5OUQKVF5tXaiNVIl3YZYCdOfrcIcpI0g=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/user/SheRa/videos',
  },
  {
    title: 'Zeekay Junior',
    description:
      'Mister Maker, Sid & Rebecca, the Phonic Fairy, and more.',
    image: 'https://yt3.ggpht.com/a/AGF-l78jUJUe2uSYGi9yzIH-tfh9cWd_TtSnjM14Pg=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCp_TjaVHcT4Dj0qpj8KOTYA',
  },
  {
    title: 'Classic Mr. Bean',
    description:
      'Digitally Remastered clips and Full episodes of Mr Bean, plus a LIVE video option.',
    image: 'https://yt3.ggpht.com/a/AGF-l78Iths0lIYmjoOgBBPu8n-nIK8nGF-xwH01QA=s288-c-k-c0xffffffff-no-rj-mo',
    url: 'https://www.youtube.com/channel/UCEwIUtFBhaI2L2PuKv0KL2g',
  },
  {
    title: 'The Berenstain Bears Official',
    description:
      'Berenstain Bears episodes available in multiple languages.',
    image: 'https://yt3.ggpht.com/a/AGF-l7_d1vHKK9Un0QjQhcukK4zLdTIQfrYEY3vndQ=s288-c-k-c0xffffffff-no-rj-mo',
    url: 'https://www.youtube.com/channel/UCGtFbbGApG0s_8mMuJd-zKg',
  },
  {
    title: 'Babar Official',
    description:
      'Classic episodes of Babar the elephant.',
    image: 'https://yt3.ggpht.com/a/AGF-l7-TbG7-TurXFGQUmc00JMmkljYRSeTxY4BMmA=s288-c-k-c0xffffffff-no-rj-mo',
    url: 'https://www.youtube.com/channel/UCFe57hUldf3jWklSRCuztgg',
  },
  {
    title: 'Little Bear',
    description:
      'Full episodes of Little Bear by Maurice Sendak.',
    image: 'https://yt3.ggpht.com/a/AGF-l78Jh61J5ouYdwyMIoUguRU0Pb96eSAlICJ4Cg=s288-c-k-c0xffffffff-no-rj-mo',
    url: 'https://www.youtube.com/channel/UCncFzSeWqySvOXEbQoEJsZg',
  },
  {
    title: "Encore: Children's Series",
    description:
      'Playlist: Mr. Dressup, Under the Umbrella Tree, Benjamin Bear, etc.',
    image: 'https://yt3.ggpht.com/a/AGF-l7-hSLFgp1Y0qTdfw-3WM1Ulf9UvnZSrMKthjg=s48-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/playlist?list=PLXssjYeUyJVFPDB7RODqdFtxFO1hWALwJ',
  },
  {
    title: 'Grand Illusions',
    description:
      'Collects unusual toys, with a collection of over 20,000 that have been bought over the last 30 years',
    image: 'https://yt3.ggpht.com/a/AGF-l79nFDzr6GprwmbnH9gaNPByL-XjIcFCHah4_w=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/user/henders007',
  },
  {
    title: 'DC Super Hero Girls',
    description:
      'Live compilation of EVERY Super Short so far introducing the world of DC Super Hero Girls',
    image: 'https://yt3.ggpht.com/a/AGF-l78jHzirNHbY80IS3_z5k2Ia-2qLE5aBmheo3A=s48-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/channel/UCRdVJ9XqHCb6BqApCIO_TYg',
  },
  {
    title: "Sesame Street",
    description:
      'Playlist of Sesame Street full episodes. (long)',
    image: 'https://yt3.ggpht.com/a/AGF-l7_N4TFUzQV4w4O4mNBUxlgIHlD6bvmwAaYfHQ=s288-mo-c-c0xffffffff-rj-k-no',
    url: 'https://www.youtube.com/playlist?list=PL8TioFHubWFsnPhBmrDQ8dtoXxghDMKxr',
  },
  {
    title: "Betsy's Kindergarten Adventures",
    description:
      'Designed to ease a child’s transition from the home to school',
    image: 'https://yt3.ggpht.com/a/AATXAJwSkyQcrJ3HGdbWAMGEjqMRTgfL4Iz6H6rp=s176-c-k-c0x00ffffff-no-rj',
    url: 'https://www.youtube.com/watch?v=Pll4dmXaYxU&list=PLG75qNVCDo1MZIZn5_P6Rg3ueOHNHWFf1',
  },
  {
    title: "Yo Gabba Gabba",
    description:
      'Hello, friends! Lets say the magic words, Yo Gabba Gabba! and get ready for lots of fun, music and dancing! ',
    image: 'https://yt3.ggpht.com/a/AATXAJyoJ_vBLUJIjYz10L891BymzJ1EPa2Y15nB5g=s100-c-k-c0xffffffff-no-rj-mo',
    url: 'https://www.youtube.com/user/yogabbagabba',
  },
  {
    title: "Barney",
    description:
      'Barney and Friends home on YouTube, where you can find the video clips and full episodes!',
    image: 'https://yt3.ggpht.com/a/AATXAJyeYzHkb0jfq97OwrQYSCJKSKDoNRCF58j9iQ=s100-c-k-c0xffffffff-no-rj-mo',
    url: 'https://www.youtube.com/channel/UCTn45orVsFOsNZHxAJcZK5g',
  },
  {
    title: "Daniel Tiger",
    description:
      'Daniel Tigers Neighbourhood Channel has clips and full episodes',
    image: 'https://yt3.ggpht.com/a/AATXAJx5DOhgRj4xIAvpcBOyBamb5VepPzRDHXMFoQ=s100-c-k-c0xffffffff-no-rj-mo',
    url: 'https://www.youtube.com/c/DanielTigersNeighbourhood',
  },
  {
    title: "Lets Go Luna",
    description:
      'Follow the hilarious adventures of three friends as they traverse the globe with their parents’ travelling performance troupe.',
    image: 'https://yt3.ggpht.com/a/AATXAJw4U90h7_55vHV0rEiIWWb-8LZNWMVvguqlEw=s88-c-k-c0x00ffffff-no-rj',
    url: 'https://www.youtube.com/channel/UC_NDbIKHXnVq7PnKUU93X-A',
  },
  {
    title: "Peg + Cat",
    description:
      'An animated preschool series that follows an adorable spirited little girl, Peg, and her sidekick, Cat.',
    image: 'https://yt3.ggpht.com/a/AATXAJyNrJMbaJR_4KItl01ZYjujZ2H0Z-jpgf7d1w=s88-c-k-c0x00ffffff-no-rj',
    url: 'https://www.youtube.com/channel/UCSTAgljf9KaBYlWSk4Q13OQ',
  },
  {
    title: "Big Comfy Couch",
    description:
      'The Canadian childrens television series about Loonette the Clown and her doll Molly.',
    image: 'https://yt3.ggpht.com/a/AATXAJyBiEhNshDJE2c9bMSX9ndx93dTp5cNMDdWDg=s176-c-k-c0x00ffffff-no-rj',
    url: 'https://www.youtube.com/channel/UC1SZ0g8QiSaV2PmkduhVIZg/featured',
  },
];

export default relax;
